body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f6f8;
}

.margin-x-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.instalationDetail section {
  display: block;
}

.instalationDetail li {
  margin-bottom: 14px;
}

.class-iterator {
  border-style: solid;
  align-content: center;
}

.class-inputs {
  width: 50%;
  margin: 10px;
}

.classButtom {
  margin: 5px;
}

.class-array-input {
  margin: 0px !important;
}

.class-user-assign-add {
  margin: 10px !important;
  border-style: solid;
  border-color: red;
}
